




























































































import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Watch } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputText from '@/components/Controls/InputText.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

type Tenant = {
  tenantName: string | null;
  tenancyArea: string | null;
  use: string | null;
  designatedCarParks: number | null;
}

type ServerData = { [key: string]: any }

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputText,
    InputTextarea,
    Calendar
  }
})
export default class RetailStripShopRetailProperty extends Vue {
  formData () {
    const data: ServerData = {
      UseDescription: this.useDescription,
      GeneralCondition: this.generalCondition,
      ApproximateBuildYear: this.approximateBuildYear,
      NumberOfLevels: this.numberOfLevels,
      CarParks: this.carParks,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      AnnualNetRental: this.annualNetRental,
      AnnualGrossRental: this.annualGrossRental,
      AnnualOutgoings: this.annualOutgoings,
      AccommodationFeatures: this.accommodationFeatures,
      PropertyFeatures: this.propertyFeatures,
      EstimatedValue: this.estimatedValue
    }

    data.Tenancies = this.tenancies.map(t => ({
      TenantName: t.tenantName,
      TenancyArea: t.tenancyArea,
      UseDescription: t.use,
      CarParks: t.designatedCarParks
    }))

    return data
  }

  loadData (data: any) {
    this.useDescription = data.UseDescription
    this.generalCondition = data.GeneralCondition
    this.approximateBuildYear = data.ApproximateBuildYear
    this.numberOfLevels = data.NumberOfLevels
    this.carParks = data.CarParks
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.annualNetRental = data.AnnualNetRental
    this.annualGrossRental = data.AnnualGrossRental
    this.annualOutgoings = data.AnnualOutgoings
    this.accommodationFeatures = data.AccommodationFeatures
    this.propertyFeatures = data.PropertyFeatures
    this.estimatedValue = data.EstimatedValue

    if (!data.Tenancies) return

    data.Tenancies.forEach((t: any) => {
      this.tenancies.push({
        tenantName: t.TenantName,
        tenancyArea: t.TenancyArea,
        use: t.UseDescription,
        designatedCarParks: t.CarParks
      })
    })

    this.numberOfTenancies = data.Tenancies.length
  }

  propertyType () {
    return 'RetailStripShopRetailProperty'
  }

  @Watch('numberOfTenancies')
  tenanciesChanged (newVal: number, oldVal: number | null) {
    if (newVal < 0) return
    if (oldVal === null) oldVal = 0
    if (newVal === this.tenancies.length) return

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        this.tenancies.push(this.newTenant())
      }
    } else {
      this.tenancies = this.tenancies.slice(0, newVal)
    }
  }

  tenancies: Tenant[] = []

  newTenant (): any {
    return {
      tenantName: null,
      tenancyArea: null,
      use: null,
      designatedCarParks: null
    }
  }

  useDescription: any = null
  generalCondition: any = null
  approximateBuildYear: any = null
  numberOfLevels: any = null
  floorConstruction: any = null
  wallConstruction: any = null
  roofConstruction: any = null
  numberOfTenancies: any = null
  annualNetRental: any = null
  annualGrossRental: any = null
  annualOutgoings: any = null
  accommodationFeatures: string[] = []
  propertyFeatures: string[] = []
  estimatedValue: any = null
  carParks: any = null
}
